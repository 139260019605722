import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    REMEMBER_SECTION,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_TIPS} from "../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {OrderEmailTemplate3} from "../../util/emailTemplates/orderEmailTemplate3";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";

const OrderForm3 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderForm3Json = [
        {
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.BUSINESS_YOU_WANT_TO_PICK_UP_FROM), '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS_OF_BUSINESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.ITEMS_FOR_PICK_UP_AND_DELIVER), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.TIME_TO_RECEIVE_DELIVERY_ORDER), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIPS), '00', true, LAYOUT_DEFAULT, HANDLER_TIPS),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.AUTHORIZE_COMPANY_TEXT), '', true, LAYOUT_DEFAULT, "NONE"),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.AGREEMENT_TEXT), '', true, LAYOUT_DEFAULT, "NONE")
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(OrderEmailTemplate3);

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm3Json} emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm3;