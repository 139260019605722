import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";

import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {useTranslation} from "react-i18next";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_ORDER_ITEMS} from "../../reducers/orderItemsReducer";
import {OrderEmailTemplate2} from "../../util/emailTemplates/orderEmailTemplate2";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";

const OrderForm2 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderForm2Json = [
        {
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_FORM), '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_PHONE), '(000) 000-00-00', false, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_PHONE), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME_OTHER_THEN_ASAP), "", true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.ORDER_DETAILS), "", true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(OrderEmailTemplate2)

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm2Json} emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm2;
