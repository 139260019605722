import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import FieldLabel from "./fieldLabel";
import {getFromCache} from "../../../util/localCache";
import { HANDLER_ORDER_NUMBER } from "../../../reducers/orderNumberReducer";

const NameInput = ({config, deliveryOnly = false, info}) => {
    const dispatch = useDispatch();
    const nameRef = useRef(null);

    useEffect(() => {
        if (config.prepopulate) {
            updateFromCache();
            document.getElementById("externalOrderForm").addEventListener("reset", updateFromCacheAfterSomeTime)
        } else if(deliveryOnly) {
            nameRef.current.value = info;
            dispatch({type: config.handlerType, payload: nameRef.current.value});
            nameRef.current.disabled = true;
        }
    }, [config?.prepopulate, deliveryOnly]);

    useEffect(() => {
        const f = document.getElementById("externalOrderForm");
        if(f) f.addEventListener("reset", resetInput);
        return () => {if(f) f.removeEventListener("reset", resetInput)};
    }, []);

    if (typeof config === "undefined") {
        return null;
    }

    const updateFromCache = () => {
        nameRef.current.value = getFromCache(config.handlerType) || "";
        dispatch({type: config.handlerType, payload: nameRef.current.value});
    }

    const updateFromCacheAfterSomeTime = () => {
        setTimeout(()=>{
            updateFromCache();
        }, 500)
    }

    const resetInput = () => {
        if(!deliveryOnly) {
            nameRef.current.value = "";
            dispatch({type: config.handlerType, payload: ''});
        }
    }

    const inputChanged = (ev) => {
        if(config.handlerType === HANDLER_ORDER_NUMBER) {
            ev.target.value = ev.target.value.replace(".", "");
        }
        dispatch({type: config.handlerType, payload: ev.target.value});
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <input ref={nameRef} type="text" className="form-control" aria-describedby="text"
                   required={config.required || false} id={config.handlerType}
                   placeholder={config.placeholder} onChange={inputChanged}/>
        </div>
    )
}

export default NameInput;
